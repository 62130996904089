<template>
  <Maps
    v-bind:centralCoordinates="centralCoordinatesDetails"
    v-if="centralCoordinatesDetails.latlang"
    :key="addressType"
  />
  <div class="address_btn">
    <br />
    <ul>
      <li>
        <span>Pickup Address : </span>
        <strong
          >{{ customerAddress?.StreetLines[0] }}
          {{ customerAddress?.StreetLines[1] }} {{ customerAddress?.City }}
          {{ customerAddress?.StateOrProvinceCode }}
          {{ customerAddress?.PostalCode }}</strong
        >
      </li>
      <li>
        <span>Pickup Time : </span>
        <strong>{{ pickupTime }}</strong>
      </li>
    </ul>
    <input type="button" value="Continue" @click="nextPage" />
  </div>
</template>

<script>
import Maps from "./Maps.vue";
import store from "../store/index.js";

export default {
  name: "ShippingPickup",
  props: {
    pickupTime: String,
    addressType: String,
  },
  components: {
    Maps,
  },
  data() {
    let latlang;
    if (
      store.state.locations &&
      store.state.locations.AddressToLocationRelationships
    ) {
      let matchedAddressGeographicCoordinates =
        store.state.locations.AddressToLocationRelationships[0]
          .MatchedAddressGeographicCoordinates;

      latlang = matchedAddressGeographicCoordinates;
    }

    let primaryAddress = store.state.applicationDetails.Addresses.find(
      (address) => address.AddressType === this.addressType
    );

    this.customerAddress = {
      StreetLines: [primaryAddress.AddressLine1, primaryAddress.AddressLine2],
      City: primaryAddress.AddressCity,
      StateOrProvinceCode: primaryAddress.AddressState,
      PostalCode: primaryAddress.AddressZipCode,
      CountryCode: "US",
    };
    return {
      centralCoordinatesDetails: {
        address: this.customerAddress,
        latlang: latlang,
      },
    };
  },
  methods: {
    nextPage: function () {
      store.dispatch("goToNextPage");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
