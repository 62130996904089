<template>
  <div id="mapid" :key="addressType"></div>
</template>

<script>
import L from "leaflet";

export default {
  name: "Maps",
  props: {
    centralCoordinates: Object,
    dropOffLocations: Array,
    addressType: String
  },
  data() {
    return {};
  },
  methods: {
    setupLeafletMap: function () {
      try {
        var mymap = L.map("mapid").setView(
          this.getCoordinatesArray(this.centralCoordinates.latlang),
          13
        );

        const defaultIcon = new L.icon({
          iconUrl: require("../../node_modules/leaflet/dist/images/marker-icon.png"),
          iconSize: [25, 41],
          iconAnchor: [25, 41],
          popupAnchor: [0, -2],
        });

        const fedexLocationIcon = new L.icon({
          iconUrl: require("../assets/images/fedex-location-icon.png"),
          iconSize: [25, 41],
          iconAnchor: [25, 41],
          popupAnchor: [0, -2],
        });

        L.tileLayer(
          "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
          {
            attribution:
              'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery � <a href="https://www.mapbox.com/">Mapbox</a>',
            maxZoom: 18,
            id: "mapbox/streets-v11",
            tileSize: 512,
            zoomOffset: -1,
            accessToken: process.env.VUE_APP_LEAFLET_MAP_ACCESS_TOKEN,
          }
        ).addTo(mymap);

        let streetLine2 = "";
        if (
          this.centralCoordinates.address.StreetLines[1] &&
          this.centralCoordinates.address.StreetLines[1] !== ""
        ) {
          streetLine2 =
            this.centralCoordinates.address.StreetLines[1] + "<br/>";
        }

        let popupContent =
          "<p>" +
          this.centralCoordinates.address.StreetLines[0] +
          "<br/>" +
          streetLine2 +
          this.centralCoordinates.address.City +
          " " +
          this.centralCoordinates.address.StateOrProvinceCode +
          " " +
          this.centralCoordinates.address.PostalCode +
          "</p>";

        L.marker(this.getCoordinatesArray(this.centralCoordinates.latlang), {
          icon: defaultIcon,
        })
          .bindPopup(popupContent)
          .openPopup()
          .addTo(mymap);
        let locationAddress;

        if (this.dropOffLocations && this.dropOffLocations.length > 0) {
          for (let i = 0; i < this.dropOffLocations.length; i++) {
            locationAddress = this.dropOffLocations[i].LocationDetail
              .LocationContactAndAddress.Address;

            if (
              locationAddress.StreetLines[1] &&
              locationAddress.StreetLines[1] !== ""
            ) {
              streetLine2 = locationAddress.StreetLines[1] + "<br/>";
            }

            popupContent =
              '<span style="color:#4D148C">' +
              this.dropOffLocations[i].LocationDetail.LocationTypeForDisplay +
              "<br/><br/>" +
              locationAddress.StreetLines[0] +
              "<br/>" +
              streetLine2 +
              locationAddress.City +
              " " +
              locationAddress.StateOrProvinceCode +
              " " +
              locationAddress.PostalCode +
              "<br/><br/>" +
              "</span>";

            L.marker(
              this.getCoordinatesArray(locationAddress.GeographicCoordinates),
              { icon: fedexLocationIcon }
            )
              .bindPopup(popupContent)
              .openPopup()
              .addTo(mymap);
          }
        }
      } catch (error) {
        console.log("error=" + error);
        // Ignore this error for now.
      }
    },
    getCoordinatesArray: function (matchedAddressGeographicCoordinates) {
      let langPlusIndex = matchedAddressGeographicCoordinates.lastIndexOf("+");
      let langMinusIndex = matchedAddressGeographicCoordinates.lastIndexOf("-");

      let index = 0;
      if (langPlusIndex !== -1 && langPlusIndex !== 0) {
        index = langPlusIndex;
      } else if (langMinusIndex !== -1 && langMinusIndex !== 0) {
        index = langMinusIndex;
      }

      let matchedAddressGeographicCoordinatesArray = [
        matchedAddressGeographicCoordinates.substring(0, index),
        matchedAddressGeographicCoordinates.substring(
          index,
          matchedAddressGeographicCoordinates.length - 1
        ),
      ];

      return matchedAddressGeographicCoordinatesArray;
    },
  },
  mounted() {
    this.setupLeafletMap();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "../../node_modules/leaflet/dist/leaflet.css";

#mapid {
 width: auto;
  height: 350px;
  
}

</style>
