<template>
  <span>
    <header>
      <div class="container">
        <div class="header_section">
          <div class="header_left">
            <h1>CC | SECURED</h1>
          </div>
          <div class="header_right">
            <p>
              Need to leave? You can log ?
              <br />back in to finish any time.
            </p>
            <a href="#">Log out</a>
          </div>
        </div>
      </div>
    </header>
  </span>
</template>

<style scoped>
/* Header code  */
header {
  color: #ffffff;
  min-height: 85px;
  margin-bottom: 60px;
  background-blend-mode: overlay, normal;
  background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) -1%,
      #aed2ec 51%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(to bottom, #1d2755, #1d2755);
}
.header_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 85px;
}
.header_left h1 {
  font-size: 29px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
}
.header_right {
  text-align: right;
  color: #aed2ec;
}
.header_right a {
  text-align: right;
  color: #aed2ec;
}

/* @media screen and (max-width: 768px) {
  #content-desktop {
    display: none;
  }
  #content-mobile {
    display: block;
  }

  header {
    margin-bottom: 0px;
  }
} */
</style>

