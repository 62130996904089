class BaseApiService {
  baseUrl = process.env.VUE_APP_API_BASE_URL;
  resource;

  constructor(resource) {
    if (!resource) throw new Error("Resource is not provided");
    this.resource = resource;
  }

  getUrl(id) {
    if (id === undefined || id === "") {
      return `${this.baseUrl}/${this.resource}`;
    } else {
      return `${this.baseUrl}/${this.resource}/${id}`;
    }
  }

  handleErrors(err) {
    // Note: here you may want to add your errors handling
    console.log({ message: "Errors is handled here", err });
  }
}

class ReadOnlyApiService extends BaseApiService {
  constructor(resource) {
    super(resource);
  }

  async fetch(config = {}) {
    try {
      const response = await fetch(this.getUrl(), config);
      return await response.json();
    } catch (err) {
      this.handleErrors(err);
    }
  }
  async get(id) {
    console.log("=================id=" + id);
    try {
      if (!id) throw Error("Id is not provided");
      const response = await fetch(this.getUrl() + id, {
        headers: {
          "x-api-key": process.env.VUE_APP_X_API_KEY,
          "Content-Type": "application/json",
        },
      });
      return await response.json();
    } catch (err) {
      this.handleErrors(err);
    }
  }
}

class ModelApiService extends ReadOnlyApiService {
  constructor(resource) {
    super(resource);
  }
  async post(data = {}) {
    try {
      const response = await fetch(this.getUrl(), {
        method: "POST",
        headers: {
          "x-api-key": process.env.VUE_APP_X_API_KEY,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      return await response.json();
    } catch (err) {
      this.handleErrors(err);
    }
  }
  async put(id, data = {}) {
    if (!id) throw Error("Id is not provided");
    try {
      const response = await fetch(this.getUrl(id), {
        method: "PUT",
        body: JSON.stringify(data),
      });
      const { id: responseId } = response.json();
      return responseId;
    } catch (err) {
      this.handleErrors(err);
    }
  }
  async delete(id) {
    if (!id) throw Error("Id is not provided");
    try {
      await fetch(this.getUrl(id), {
        method: "DELETE",
      });
      return true;
    } catch (err) {
      this.handleErrors(err);
    }
  }
}

class ApplicationDetailsService extends ReadOnlyApiService {
  constructor() {
    super("titleadminsvc/api/application?token=");
  }
}

class LocationsApiService extends ModelApiService {
  constructor() {
    super("fedexsvc/api/locations/search");
  }
}

class AvailablePickupTimesService extends ModelApiService {
  constructor() {
    super("fedexsvc/api/pickup-service/get-pickup-availability");
  }
}

class PostsApiService extends ModelApiService {
  constructor() {
    super("posts");
  }
}

class AlbumsApiService extends ModelApiService {
  constructor() {
    super("albums");
  }
  async uploadImage() {
    /*
		Here you create you images uploading logic
		Unfortunately, jsonplaceholder don't provide url to upload files
		 */
    console.log({ message: "Image has been uploaded successfully!" });
    return true;
  }

  async triggerError() {
    try {
      throw Error("This error is triggered and handled by api module");
    } catch (err) {
      this.handleErrors(err);
    }
  }
}

class ShippingLabelApiService extends ModelApiService {
  constructor() {
    super("fedexsvc/api/ship-service/create-shipping-label");
  }
}

class SubmitApiService extends ModelApiService {
  constructor() {
    super("titleadminsvc/api/application/submit");
  }
}

class DocumentApiService extends BaseApiService {
  constructor() {
    super("titleadminsvc/api/application/get-print-documents?");
  }

  // async get() {
  //   try {
  //     const response = await fetch(this.getUrl(), {
  //       headers: {
  //         "x-api-key": process.env.VUE_APP_X_API_KEY,
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     return await response.json();
  //   } catch (err) {
  //     this.handleErrors(err);
  //   }
  // }

  async get(addressType, applicationNumber, trackingNumber) {
    //"?AddressType=Primary&ApplicationNumber=2233&TrackingNumber=1234"
    const adType = "AddressType=" + addressType + "&";
    const appNum = "ApplicationNumber=" + applicationNumber + "&";
    const trNum = "TrackingNumber=" + trackingNumber;

    try {
      const response = await fetch(this.getUrl() + adType + appNum + trNum, {
        headers: {
          "x-api-key": process.env.VUE_APP_X_API_KEY,
          "Content-Type": "application/json",
        },
      });
      return await response.json();
    } catch (err) {
      this.handleErrors(err);
    }
  }

}


class DisclaimerApiService extends BaseApiService {
  constructor() {
    super("titleadminsvc/api/disclaimers/getDisclaimer");
  }


  async getDisclaimer(screenId) {
    try {
      const response = await fetch(this.getUrl(), {
        method: "POST",
        headers: {
          "x-api-key": process.env.VUE_APP_X_API_KEY,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          screenId: screenId
        })
      });
      return await response.json();
    } catch (err) {
      this.handleErrors(err);
    }
  }

}

export const $api = {
  applicationDetails: new ApplicationDetailsService(),
  locations: new LocationsApiService(),
  availablePickupTimes: new AvailablePickupTimesService(),
  posts: new PostsApiService(),
  albums: new AlbumsApiService(),
  shippingLabel: new ShippingLabelApiService(),
  submitApi: new SubmitApiService(),
  documentApi: new DocumentApiService(),
  disclaimerApi: new DisclaimerApiService()
};
