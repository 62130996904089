<template>
<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Options</title>
    <link rel="stylesheet" href="css/style.css">
</head>
<body>
  <div>
    <header>
    <Header></Header></header>

    <div class="container">
        <div class="mid_body">
          <Progress></Progress>
          <router-view></router-view>
        </div>
    </div>
  </div>
</body>
</html>
</template>

<script>
import store from "./store/index.js";
import Header from "./components/Header.vue";
import Progress from "./components/Progress.vue";

import { $api } from "@/services/api";
export default {
  name: "app",
  mounted() {},
  data() {
    return {
      schedulingOptions: {
        uberPickUpFlag: false,
        shippingPickUpFlag: false,
        droppOffAtShippingLocationFlag: false,
      },
    };
  },
  created: function () {
    // `this` points to the vm instance
    store.dispatch("setActivePage");
    store.dispatch("getDisclaimers")
  },
  components: {
    Header,
    Progress
  },
  computed: {
    deliveryChoice() {
      return store.state.deliveryChoice;
    },
  },
  mixins: [
    {
      computed: {
        $api: () => $api,
      },
    },
  ],
};
</script>

<style>
@import "./assets/styles/style.css";
</style>
