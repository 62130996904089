<template>
  <section>
    <div class="option_header">
      <h2>Print and include all documents below</h2>
      <p class="option_sub_title">
        All documents are required in order to fund your loan
      </p>
    </div>
    <div>
      <loading
        v-model:active="isLoading"
        :can-cancel="true"
        :is-full-page="fullPage"
        color="#00529b"
      />
      <ul class="print_doc">
        <li>
          <div class="icon_box">
            <img src="@/assets/images/doc-checklist.svg" />
          </div>
          <div class="text_box">
            <strong
              ><a href="#" style="color: #00529b; text-decoration: none"
                >Title Document Checklist</a
              ></strong
            >
            <span
              >Review and include this checklist with your documents when
              shipping</span
            >
          </div>
        </li>
        <li>
          <div class="icon_box">
            <img src="@/assets/images/application.svg" />
          </div>
          <div class="text_box">
            <strong
              ><a href="#" style="color: #00529b; text-decoration: none"
                >Title Application</a
              ></strong
            >
            <span
              >This application should be signed by you and is imperitive to
              securing your loan</span
            >
          </div>
        </li>
        <li>
          <div class="icon_box">
            <img src="@/assets/images/vehicle-title.svg" />
          </div>
          <div class="text_box">
            <strong
              ><a href="#" style="color: #00529b; text-decoration: none"
                >Vehicle Title</a
              ></strong
            >
            <span
              >This is your vehicle title and should be provided by you</span
            >
          </div>
        </li>
        <li>
          <div class="icon_box">
            <img src="@/assets/images/shipping-label.svg" />
          </div>
          <div class="text_box">
            <strong
              ><a
                target="_blank"
                :href="shippingLabelUrl"
                style="color: #00529b; text-decoration: none"
                >Shipping Label</a
              ></strong
            >
            <span>This is the shipping label for your document package</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="footer_btn">
      <input @click="submit" type="button" value="Print & Continue" />
      <a @click="noPrinter" class="back_link" href="#!">I do not have access to a printer</a>
      <a @click="previousPage" class="back_link" href="#!">Back</a>
    </div>
  </section>
</template>

<script>
var $ = require("jquery");
$(document).ready(function () {
  $(".acc__title").click(function (j) {
    var dropDown = $(this).closest(".acc__card").find(".acc__panel");
    $(this).closest(".acc").find(".acc__panel").not(dropDown).slideUp();

    if ($(this).hasClass("active")) {
      $(this).removeClass("active");
    } else {
      $(this).closest(".acc").find(".acc__title.active").removeClass("active");
      $(this).addClass("active");
    }

    dropDown.stop(false, true).slideToggle();
    j.preventDefault();
  });
});
import store from "../store/index.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Axios from 'axios'
export default {
  data() {
    return {
      shippingLabelUrl: "",
      isLoading: false,
      fullPage: true,
      documentUrl: null
    };
  },
  components: { Loading },
  methods: {
    noPrinter: function() {
      store.dispatch("noPrinter")
    },
    previousPage: function () {
      store.dispatch("goToPreviousPage");
    },
    nextPage: function () {
      store.dispatch("goToNextPage");
    },
    submit: function () {
      this.downloadItem();
      store.dispatch("submit");
      this.nextPage();
    },
    downloadItem() {
      store.dispatch("getDocument")
      .then(() => {
        this.documentUrl = store.state.documentResponse.url

        Axios.get(this.documentUrl, { responseType: 'blob' })
          .then(response => {
            const blob = new Blob([response.data], { type: 'application/pdf' })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = "Your Shipping Documents"
            link.click()
            URL.revokeObjectURL(link.href)
          }).catch(console.error)
      })
      .catch(err => {
        console.log(err)
      })
    },
  },
  async mounted() {
    let shippingLabel = store.state.shippingLabel;
    this.isLoading = true;
    await store.dispatch("fetchShippingLabel");
    // await store.dispatch('getDocument')
    (shippingLabel = store.state.shippingLabel),
      // this.documentUrl = store.state.documentResponse;
      (this.shippingLabelUrl = shippingLabel.shippingLabelUrl);
    this.isLoading = false;
  },
};
</script>

<style>
@import "/assets/styles/style.css";
</style>
