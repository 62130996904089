<template>
  <section>
    <div class="option_header">
      <h2>One last step - Deliver documents and get your funds!</h2>
      <div class="option_logout">
        <p>Services based on your address</p>

        <select
          v-bind="selectedAddress"
          v-if="addresses"
          style="font-family: 'Source Sans Pro', sans-serif; color: #00529b"
          @change="onAddressChange($event)"
        >
          <option
            v-for="address in addresses"
            :key="address?.AddressType"
            :value="address?.AddressType"
          >
            {{ address?.AddressLine1 }}
            {{ address?.AddressLine2 }} {{ address?.AddressCity }}
            {{ address?.AddressState }}
            {{ address?.AddressZipCode }}
          </option>
        </select>
      </div>
    </div>

    <loading
      v-model:active="isLoading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="#00529b"
    />

    <div class="option_section">
      <h3>Scheduling Options:</h3>
      <SchedulingOption
        title="Quickest Option"
        name="Shipping Pickup"
        v-bind:description="shippingPickupDescription"
        @selected-option="handleSelectedOption"
      />

      <div id="shipping-div" v-if="showShippingPickup">
        <ShippingPickup
          v-bind:pickupTime="pickupTime"
          v-bind:addressType="addressType"
          :key="addressType"
        />
      </div>

      <SchedulingOption
        title="Second Quickest Option"
        name="Drop Off at Shipping Location"
        v-bind:description="dropOffLocationsDescription"
        @selected-option="handleSelectedOption"
      />

      <div id="dropoff-div" v-if="showDropoffLocations">
        <DropoffLocations v-bind:addressType="addressType" :key="addressType" />
      </div>
    </div>
  </section>
</template>

<script>
import ShippingPickup from "./ShippingPickup.vue";
import DropoffLocations from "./DropoffLocations.vue";
import SchedulingOption from "./SchedulingOption.vue";
import store from "../store/index.js";
import Loading from "vue-loading-overlay";
import dateformat from "dateformat";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  data() {
    return {
      showShippingPickup: false,
      showDropoffLocations: false,
      dropOffLocationsDescription: this.dropOffLocationsDescription,
      shippingPickupDescription: this.shippingPickupDescription,
      customerAddress: this.customerAddress,
      isLoading: false,
      fullPage: true,
      selectedAddress: "",
      addresses: this.addresses,
      addressType: this.addressType,
      pickupTime : this.pickupTime,
    };
  },
  components: {
    SchedulingOption,
    ShippingPickup,
    DropoffLocations,
    Loading
  },
  methods: {
    handleSelectedOption(selectedOption) {
      store.commit("updateDeliveryChoice", selectedOption);

      if (selectedOption === "Shipping Pickup") {
        this.showShippingPickup = true;
        this.showDropoffLocations = false;
      } else if (selectedOption === "Drop Off at Shipping Location") {
        this.showShippingPickup = false;
        this.showDropoffLocations = true;
      }
      store.commit("setActivePage");
    },

    async onAddressChange(event) {
      if (this.addresses) {
        let primaryAddress = this.addresses.find(
          (address) => address.AddressType === event.target.value
        );

        this.customerAddress = {
          StreetLines: [
            primaryAddress.AddressLine1,
            primaryAddress.AddressLine2,
          ],
          City: primaryAddress.AddressCity,
          StateOrProvinceCode: primaryAddress.AddressState,
          PostalCode: primaryAddress.AddressZipCode,
          CountryCode: "US",
        };

        let getAvailablePickupTimesRequest = {
          Address: this.customerAddress,
        };

        this.isLoading = true;

        await Promise.all([
          store.dispatch(
            "fetchAvailablePickupTimes",
            getAvailablePickupTimesRequest
          ),
          store.dispatch(
	    "fetchLocations", 
	    this.customerAddress),
        ]);

        let availablePickupTimes = store.state.availablePickupTimes;
        let locations = store.state.locations;

        this.isLoading = false;

        if (availablePickupTimes.HighestSeverity === "SUCCESS") {
          if (availablePickupTimes.Options.length > 0) {
            this.availablePickUpDate =
              availablePickupTimes.Options[0].PickupDate;

            if (store.state.availablePickupTimes.Options[0].PickupDate) {
                this.availablePickUpDate = availablePickupTimes.Options[0].PickupDate.substring(
			0,
			10
		);
            }

            this.availablePickupTime =
              availablePickupTimes.Options[0].CutOffTime;

            this.shippingPickupDescription =
              "Driver is available to pick up your documents on <span>" +
              dateformat(
	        this.availablePickUpDate, 
		"mmmm dd, yyyy"
	      ) +
              "</span> at <span>" +
              dateformat(
		this.availablePickUpDate + " " + this.availablePickupTime,
		"h:MM TT"
	      ) +
              "</span>";

	    this.pickupTime = dateformat(
		this.availablePickUpDate + " " + this.availablePickupTime,
		"h:MM TT"
	    );
          }
        }

        if (locations.HighestSeverity === "SUCCESS") {
          this.totalResultsAvailable = locations.TotalResultsAvailable;
          this.dropOffLocationsDescription =
            "There are <span>" +
            this.totalResultsAvailable +
            "</span> drop off locations within 50 miles";
        }
      }

      this.addressType = event.target.value;
      await store.dispatch("setSelectedAddressType", this.addressType);
    },

    async refreshServices() {
      if (this.addresses) {
        let primaryAddress = this.addresses.find(
          (address) => address.AddressType === this.addressType
        );

        this.customerAddress = {
          StreetLines: [
            primaryAddress.AddressLine1,
            primaryAddress.AddressLine2,
          ],
          City: primaryAddress.AddressCity,
          StateOrProvinceCode: primaryAddress.AddressState,
          PostalCode: primaryAddress.AddressZipCode,
          CountryCode: "US",
        };

        let getAvailablePickupTimesRequest = {
          Address: this.customerAddress,
        };

        this.isLoading = true;

        await Promise.all([
          store.dispatch(
            "fetchAvailablePickupTimes",
            getAvailablePickupTimesRequest
          ),
          store.dispatch(
	    "fetchLocations", 
	    this.customerAddress),
        ]);

	console.log('this.customerAddress-' + JSON.stringify(this.customerAddress));

        let availablePickupTimes = store.state.availablePickupTimes;
        let locations = store.state.locations;

        this.isLoading = false;

        if (availablePickupTimes.HighestSeverity === "SUCCESS") {
          if (availablePickupTimes.Options.length > 0) {
            this.availablePickUpDate =
              availablePickupTimes.Options[0].PickupDate;

            if (store.state.availablePickupTimes.Options[0].PickupDate) {
              this.availablePickUpDate = availablePickupTimes.Options[0].PickupDate;
            }

            this.availablePickupTime =
              availablePickupTimes.Options[0].CutOffTime;

            this.shippingPickupDescription =
              "Driver is available to pick up your documents on <span>" +
              dateformat(
	        this.availablePickUpDate, 
		"mmmm dd, yyyy"
	      ) +
              "</span> at <span>" +
              dateformat(
		this.availablePickUpDate + " " + this.availablePickupTime,
		"h:MM TT"
	      ) +
              "</span>";

	     this.pickupTime = dateformat(
		this.availablePickUpDate + " " + this.availablePickupTime,
		"h:MM TT"
	    );
          }
        }

        if (locations.HighestSeverity === "SUCCESS") {
          this.totalResultsAvailable = locations.TotalResultsAvailable;
          this.dropOffLocationsDescription =
            "There are <span>" +
            this.totalResultsAvailable +
            "</span> drop off locations within 50 miles";
        }

        await store.dispatch("setSelectedAddressType", this.addressType);
      }
    },
  },
  async created() {
    // this.handleSelectedOption(store.state.deliveryChoice);
    //This line would sync the selection, if the user uses "back" button from a future screen. Issue w/ binding to the radio button currently, so is disabled.

    let applicationDetails = store.state.applicationDetails;
    let availablePickupTimes = store.state.availablePickupTimes;
    let locations = store.state.locations;
    let id = this.$route.query.id;

    if (id !== undefined && id !== "") {
      this.isLoading = true;
      await store.dispatch("fetchApplicationDetails", id);

      applicationDetails = store.state.applicationDetails;

      if (
        applicationDetails &&
        applicationDetails.Addresses &&
        applicationDetails.Addresses.length > 0
      ) {
        this.addresses = applicationDetails.Addresses;

        this.addressType = "primary";
        await store.dispatch("setSelectedAddressType", this.addressType);

        let primaryAddress = this.addresses.find(
          (address) => address.AddressType === "primary"
        );

        this.customerAddress = {
          StreetLines: [
            primaryAddress.AddressLine1,
            primaryAddress.AddressLine2,
          ],
          City: primaryAddress.AddressCity,
          StateOrProvinceCode: primaryAddress.AddressState,
          PostalCode: primaryAddress.AddressZipCode,
          CountryCode: "US",
        };

        let getAvailablePickupTimesRequest = {
          Address: this.customerAddress,
        };

        await Promise.all([
          store.dispatch(
            "fetchAvailablePickupTimes",
            getAvailablePickupTimesRequest
          ),
          store.dispatch("fetchLocations", this.customerAddress),
        ]);

        availablePickupTimes = store.state.availablePickupTimes;
        locations = store.state.locations;

        this.isLoading = false;
      }
    }

    if (
      applicationDetails &&
      applicationDetails.Addresses &&
      applicationDetails.Addresses.length > 0
    ) {
      this.addresses = applicationDetails.Addresses;

      let primaryAddress = this.addresses.find(
        (address) => address.AddressType === "primary"
      );

      this.customerAddress = {
        StreetLines: [primaryAddress.AddressLine1, primaryAddress.AddressLine2],
        City: primaryAddress.AddressCity,
        StateOrProvinceCode: primaryAddress.AddressState,
        PostalCode: primaryAddress.AddressZipCode,
        CountryCode: "US",
      };
    }

    if (availablePickupTimes.HighestSeverity === "SUCCESS") {
      if (availablePickupTimes.Options.length > 0) {
        this.availablePickUpDate = availablePickupTimes.Options[0].PickupDate;

        if (store.state.availablePickupTimes.Options[0].PickupDate) {
          this.availablePickUpDate = availablePickupTimes.Options[0].PickupDate.substring(
            0,
            10
          );
        }

        this.availablePickupTime = availablePickupTimes.Options[0].CutOffTime;

        this.shippingPickupDescription =
          "Driver is available to pick up your documents on <span>" +
	  dateformat(
		this.availablePickUpDate, 
		"mmmm dd, yyyy"
	  ) +
          "</span> at <span>" +
          dateformat(
		this.availablePickUpDate + " " + this.availablePickupTime,
		"h:MM TT"
	  ) +
          "</span>";
      }

      this.pickupTime = dateformat(
	this.availablePickUpDate + " " + this.availablePickupTime,
	"h:MM TT"
      );
    }

    if (locations.HighestSeverity === "SUCCESS") {
      this.totalResultsAvailable = locations.TotalResultsAvailable;
      this.dropOffLocationsDescription =
        "There are <span>" +
        this.totalResultsAvailable +
        "</span> drop off locations within 50 miles";
    }
  },
};
</script>

<style>
@import "/assets/styles/style.css";
</style>
