<template>
  <span>
    <span id="content-desktop">
      <aside>
        <ul class="stepper stepper-vertical">
          <li :class="progressStates[0].css_active">
            <a href="#!">
              <span class="circle" v-if="!progressStates[0].showCheckMark"
                >1
              </span>
              <span class="circle" v-if="progressStates[0].showCheckMark"
                >&#10004;
              </span>
              <span class="label">Schedule Pickup</span>
            </a>
          </li>
          <li :class="progressStates[1].css_active">
            <a href="#!">
              <span class="circle" v-if="!progressStates[1].showCheckMark"
                >2</span
              >
              <span class="circle" v-if="progressStates[1].showCheckMark"
                >&#10004;</span
              >
              <span class="label">Confirm Appointment</span>
            </a>
          </li>
          <li :class="progressStates[2].css_active">
            <a href="#!">
              <span class="circle" v-if="!progressStates[2].showCheckMark"
                >3</span
              >
              <span class="circle" v-if="progressStates[2].showCheckMark"
                >&#10004;
              </span>
              <span class="label">Print Documents</span>
            </a>
          </li>
          <li :class="progressStates[3].css_active">
            <a href="#!">
              <span class="circle">4</span>
              <span class="label">Get Funds!</span>
            </a>
          </li>
        </ul>
        <br />
      </aside>
    </span>
    <span id="content-mobile">
      <ul class="stepper stepper-horizontal">
        <li :class="progressStates[0].css_active">
          <a href="#!">
            <span class="circle" v-if="!progressStates[0].showCheckMark"
              >1
            </span>
            <span class="circle" v-if="progressStates[0].showCheckMark"
              >&#10004;
            </span>
            <span class="label">Schedule Pickup</span>
          </a>
        </li>
        <li :class="progressStates[1].css_active">
          <a href="#!">
            <span class="circle" v-if="!progressStates[1].showCheckMark"
              >2</span
            >
            <span class="circle" v-if="progressStates[1].showCheckMark"
              >&#10004;</span
            >
            <span class="label" style="font-size:9px;">Confirm Appointment</span>
          </a>
        </li>
        <li :class="progressStates[2].css_active">
          <a href="#!">
            <span class="circle" v-if="!progressStates[2].showCheckMark"
              >3</span
            >
            <span class="circle" v-if="progressStates[2].showCheckMark"
              >&#10004;
            </span>
            <span class="label">Print Documents</span>
          </a>
        </li>
        <li :class="progressStates[3].css_active">
          <a href="#!">
            <span class="circle">4</span>
            <span class="label">Get Funds!</span>
          </a>
        </li>
      </ul>
    </span>
  </span>
</template>

<script>
import store from "../store/index.js";
export default {
  components: {},
  methods: {
    previousPage: function () {
      store.dispatch("goToPreviousPage");
    },
    nextPage: function () {
      store.dispatch("goToNextPage");
    },
  },
  computed: {
    progressStates() {
      return store.state.progressStates;
    },
    schedule_Active_CSS_Class() {
      return store.state.schedule_Active_CSS_Class;
    },
    confirm_Active_CSS_Class() {
      return store.state.confirm_Active_CSS_Class;
    },
    print_Active_CSS_Class() {
      return store.state.print_Active_CSS_Class;
    },
    getfunds_Active_CSS_Class() {
      return store.state.getfunds_Active_CSS_Class;
    },
  },
};
</script>

<style>
@import "/assets/styles/style.css";

.previous {
  background-color: #f1f1f1;
  color: black;
}

.next {
  background-color: #04aa6d;
  color: white;
}

.round {
  border-radius: 50%;
  margin: 20px;
}
</style>
