<template>
  <div class="radio_container">
    <p class="radio_title">{{ title }}</p>
    <input
      type="radio"
      v-bind:id="name"
      v-bind:value="name"
      name="scheduling-options"
      @click="$emit('selected-option', name)"
    />
    <label v-bind:for="name">{{ name }}</label>
    <p class="radio_time" v-html="description" />
  </div>
</template>

<script>
export default {
  name: "SchedulingOption",
  props: {
    title: String,
    name: String,
    description: String,
  },
  emits: ["selected-option"],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
@import "/assets/styles/style.css";
</style>
