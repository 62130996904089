import { createStore } from "vuex";
import router from "../router/index";
import storePlugins from "../plugins/storePlugins";

const ADD_APPLICATION_DETAILS = "ADD_APPLICATION_DETAILS";
const ADD_LOCATIONS = "ADD_LOCATIONS";
const ADD_AVAILABLE_PICKUP_TIMES = "ADD_AVAILABLE_PICKUP_TIMES";
const ADD_POST = "ADD_POST";
const UPDATE_POST = "UPDATE_POST";
const UPDATE_PICKUP_TIME = "UPDATE_PICKUP_TIME";
const ADD_SHIPPING_LABEL = "ADD_SHIPPING_LABEL";
const UPDATE_SELECTED_ADDRESS_TYPE = "UPDATE_SELECTED_ADDRESS_TYPE";

export default createStore({
  plugins: [storePlugins],
  state: {
    progressStates: [
      {
        route: "",
        showCheckMark: false,
        activeIndex: 0,
        checkMarkIndex: 0,
        css_active: "",
      },
      {
        route: "confirmAppointment",
        showCheckMark: false,
        activeIndex: 1,
        checkMarkIndex: 1,
        css_active: "",
      },
      {
        route: "printDocument",
        showCheckMark: false,
        activeIndex: 2,
        checkMarkIndex: 2,
        css_active: "",
      },
      {
        route: "documentDeliveryComplete",
        showCheckMark: false,
        activeIndex: 3,
        checkMarkIndex: 3,
        css_active: "",
      },
    ],
    pickUp_progressStates: [
      {
        route: "",
        showCheckMark: false,
        activeIndex: 0,
        checkMarkIndex: 0,
        css_active: "",
      },
      {
        route: "confirmAppointment",
        showCheckMark: false,
        activeIndex: 1,
        checkMarkIndex: 1,
        css_active: "",
      },
      {
        route: "printDocument",
        showCheckMark: false,
        activeIndex: 2,
        checkMarkIndex: 2,
        css_active: "",
      },
      {
        route: "documentDeliveryComplete",
        showCheckMark: false,
        activeIndex: 3,
        checkMarkIndex: 3,
        css_active: "",
      },
    ],
    dropOff_progressStates: [
      {
        route: "",
        showCheckMark: false,
        activeIndex: 0,
        checkMarkIndex: 0,
        css_active: "",
      },
      {
        route: "dropOffConfirm",
        showCheckMark: false,
        activeIndex: 1,
        checkMarkIndex: 1,
        css_active: "",
      },
      {
        route: "printDocument",
        showCheckMark: false,
        activeIndex: 2,
        checkMarkIndex: 2,
        css_active: "",
      },
      {
        route: "documentDeliveryComplete",
        showCheckMark: false,
        activeIndex: 3,
        checkMarkIndex: 3,
        css_active: "",
      },
    ],
    progressIndex: 0,
    users: [],
    applicationDetails: {},
    locations: [],
    availablePickupTimes: [],
    posts: [],
    selectedPickupTime: {},
    shippingLabel: {},
    documentResponse: {},
    submitApiResponse: {},
    deliveryChoice: null,
    selectedAddressType: null,
    disclaimerResponse: [{
      DisclaimerText: ""
    }]
  },
  mutations: {
    setActivePage: (state) => {
      for (let i = 0; i < state.progressStates.length; i++) {
        //Set Active Class for Green Circle
        if (state.progressIndex >= state.progressStates[i].activeIndex) {
          state.progressStates[i].css_active = "active";
        } else {
          state.progressStates[i].css_active = "";
        }
        //Set CheckMark based on CheckMarkIndex
        if (state.progressIndex > state.progressStates[i].checkMarkIndex) {
          state.progressStates[i].showCheckMark = true;
        } else {
          state.progressStates[i].showCheckMark = false;
        }
      }
    },
    goToNextPage: (state) => {
      if (
        state.progressIndex < state.progressStates.length - 1 &&
        state.deliveryChoice != null
      ) {
        state.progressIndex++;
      }
    },
    goToPreviousPage: (state) => {
      if (state.progressIndex > 0) {
        state.progressIndex--;
      }
    },
    updateDeliveryChoice(state, message) {
      state.deliveryChoice = message;
      if (state.deliveryChoice == "Drop Off at Shipping Location") {
        state.progressStates = state.dropOff_progressStates;
      }
      if (state.deliveryChoice == "Shipping Pickup") {
        state.progressStates = state.pickUp_progressStates;
      }
    },
    [ADD_APPLICATION_DETAILS](state, applicationDetails) {
      state.applicationDetails = applicationDetails;
    },
    [ADD_AVAILABLE_PICKUP_TIMES](state, availablePickupTimes) {
      state.availablePickupTimes = availablePickupTimes;
    },
    [ADD_LOCATIONS](state, locations) {
      state.locations = locations;
    },
    [ADD_POST](state, post) {
      state.posts = [...state.posts, post];
    },
    [UPDATE_POST](state, post) {
      const index = state.posts.findIndex(({ id }) => id === post.id);
      if (!~index) state.posts.splice(index, 1, post);
    },
    [UPDATE_PICKUP_TIME](state, pickupTime) {
      state.selectedPickupTime = pickupTime;
    },
    [ADD_SHIPPING_LABEL](state, shippingLabel) {
      state.shippingLabel = shippingLabel;
    },
    [UPDATE_SELECTED_ADDRESS_TYPE](state, selectedAddressType) {
      state.selectedAddressType = selectedAddressType;
    },
    saveDocumentResponse(state, post){
      state.documentResponse = post
    }
  },
  actions: {
    noPrinter({ commit, state }) {
      commit("updateDeliveryChoice", "Drop Off at Shipping Location")
      state.progressIndex = 1;
      router.replace("/" + state.progressStates[state.progressIndex].route);
      window.scrollTo(0, 0);
    },
    goToNextPage({ commit, state }) {
      commit("goToNextPage");
      commit("setActivePage");
      router.replace("/" + state.progressStates[state.progressIndex].route);
      window.scrollTo(0, 0);
    },
    goToPreviousPage({ commit, state }) {
      commit("goToPreviousPage");
      commit("setActivePage");
      router.replace("/" + state.progressStates[state.progressIndex].route);
      window.scrollTo(0, 0);
    },
    setActivePage({ commit }) {
      commit("setActivePage");
    },
    // async fetchUsers({ commit }, config) {
    //   const users = await this.$api.users.fetch(config);
    //   commit(ADD_USERS, users);
    //   console.log({ message: "Users from vuex", users });
    // },

    async fetchApplicationDetails({ commit }, config) {
      const applicationDetails = await this.$api.applicationDetails.get(config);
      commit(ADD_APPLICATION_DETAILS, applicationDetails);
      console.log({
        message: "Application Details from vuex",
        applicationDetails,
      });
    },
    async fetchAvailablePickupTimes({ commit }, post) {
      const availablePickupTimes = await this.$api.availablePickupTimes.post(
        post
      );
      commit(ADD_AVAILABLE_PICKUP_TIMES, availablePickupTimes);
      console.log({
        message: "Received availablePickupTimes",
        availablePickupTimes,
      });
    },
    async fetchLocations({ commit }, post) {
      const locations = await this.$api.locations.post(post);
      commit(ADD_LOCATIONS, locations);
      console.log({ message: "Received locations", locations });
    },
    async createPost({ commit }, post) {
      const id = await this.$api.posts.post(post);
      commit(ADD_POST, { ...post, id });
      console.log({ message: "Created post", post: { ...post, id } });
    },
    async updatePost({ commit }, post) {
      const id = await this.$api.posts.put(post.id, post);
      commit(UPDATE_POST, post);
      console.log({ message: "Updated post", post: { post, id } });
    },
    async fetchShippingLabel({state}) {
      let chosenAddress = state.applicationDetails.Addresses.find(
        (address) => address.AddressType === state.selectedAddressType
      );
      let shippingRequest = {
        Shipper: {
          Contact: {
            PersonName:
              state.applicationDetails.ApplicantFirstName +
              " " +
              state.applicationDetails.ApplicantLastName,
            PhoneNumber: state.applicationDetails.CellPhoneNumber,
          },
          Address: {
            StreetLines:
              chosenAddress.AddressLine1 + " " + chosenAddress.AddressLine2,
            City: chosenAddress.AddressCity,
            StateOrProvinceCode: chosenAddress.AddressState,
            PostalCode: chosenAddress.AddressZipCode,
            CountryCode: "US",
          },
          ApplicationNumber: state.applicationDetails.SourceApplicationId,
          AddressType: chosenAddress.AddressType,
        },
      };
      console.log({ message: "shipping label request", shippingRequest: shippingRequest })

      const shippingLabel = await this.$api.shippingLabel.post(shippingRequest);
      this.commit(ADD_SHIPPING_LABEL, shippingLabel);
      console.log({ message: "shipping label response", shippingLabel: shippingLabel });
    },

    async updatePickupTime({ commit }, pickupTime) {
      console.log(pickupTime);
      commit(UPDATE_PICKUP_TIME, pickupTime);
      console.log({
        message: "updated pickuptime",
        selectedPickupTime: this.state.selectedPickupTime,
      });
    },

    async setSelectedAddressType({ commit }, selectedAddressType) {
      commit(UPDATE_SELECTED_ADDRESS_TYPE, selectedAddressType);
      console.log({
        message: "updated selectedAddressType",
        selectedPickupTime: this.state.selectedAddressType,
      });
    },
    async submit() {
      var submitRequest = {
        AppId: this.state.applicationDetails.AppId,
        SourceApplicationId: this.state.applicationDetails.SourceApplicationId,
        ApplicantFirstName: this.state.applicationDetails.ApplicantFirstName,
        ApplicantLastName: this.state.applicationDetails.ApplicantLastName,
        ApplicantMiddleName: this.state.applicationDetails.ApplicantMiddleName,
        CellPhoneNumber: this.state.applicationDetails.CellPhoneNumber,
        LoanId: this.state.applicationDetails.LoanId,
        Vin: this.state.applicationDetails.Vin,
        TitleNumber: this.state.applicationDetails.TitleNumber,
        ApplicationStatusId: this.state.applicationDetails.ApplicationStatusId,
        TitleStatusId: this.state.applicationDetails.TitleStatusId,
        selectedAddressType: this.state.selectedAddressType,
        Addresses: new Array(this.state.applicationDetails.Addresses.find(address => address.AddressType === this.state.selectedAddressType)),
        ApplicationDocuments: this.state.ApplicationDocuments,
        PickupOptions: new Array(this.state.selectedPickupTime),
        DropoffLocations: [],
        ShippingRequests: [
          {
            AddressId: 18,
            TrackingNumber: "1Z9V102W1390345271",
          },
          {
            AddressId: 19,
            TrackingNumber: "1Z9V102W1390345271"
          }
        ]

      }
      console.log({ message: "submit application request", submit: submitRequest });
      const submitApiResponse = await this.$api.submitApi.post(submitRequest);
      // router.push('/documentDeliveryComplete')
      console.log({ message: "submit application", submit: submitApiResponse });
    },
    async getDocument({ state}) {
      await this.$api.documentApi.get(state.selectedAddressType, state.applicationDetails.SourceApplicationId, state.shippingLabel.CompletedShipmentDetail.MasterTrackingId.TrackingNumber).then(response =>{
        state.documentResponse = response
        console.log({
          message: "documentResponse",
          documentResponse: state.documentResponse,
        });
      });  
    },
    async getDisclaimers({ state}) {
      await this.$api.disclaimerApi.getDisclaimer(null).then(response =>{
        state.disclaimerResponse = response
        console.log({
          message: "disclaimerResponse",
          disclaimerResponse: state.disclaimerResponse,
        });
      });  
    },

  },

});
