<template>
  <section class="bg_img">
    <div class="option_header">
      <h2>Your document pickup/dropoff has been scheduled!</h2>
      <p class="option_sub_title">
        We will send status updates to
        <span>(555) 555-5555</span>
      </p>
    </div>
    <loading
      v-model:active="isLoading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="#00529b"
    />
    <div class="doc_img">
      <img src="@/assets/images/doc.png" />
    </div>
    <div class="footer_btn">
      <p class="doc_text">
        Keep in mind that your loan funding is dependent on receipt of
        documents. To ensure that funds are disbused in a timely manner, please
        make sure all documents are correct and delivered ASAP.
      </p>
      <a @click="previousPage" class="back_link" href="#!"
        >I need to reprint my documents</a
      >
    </div>
    <div class="footer_text">
      <p>
        Old boys club thinking outside the box. Pass the mayo, appeal to the
        client, sue the vice president teams were able to drive adoption and
        awareness everyone thinks the soup tastes better after they’ve pissed in
        it punter quarterly sales are at an all-time low yet back-end of third
        quarter. Pipeline build on a culture of contribution and inclusion. All
        hands on deck how much bandwidth do you have quick-win nor this is
        meaningless loop back. Please advise soonest circle back and post launch
        highlights yet low-hanging fruit so can we align on lunch orders, and
        synergestic actionables. How much bandwidth do you have performance
        review for a tentative event rundown is attached for your reference,
        including other happenings on the day you are most welcome to join us
        beforehand for a light lunch we would also like to invite you to other
        activities on the day, including the interim and closing panel
        discussions on the intersection of businesses and social innovation, and
        on building a stronger social innovation eco-system respectively yet
        driving the initiative forward bake it in. Regroup bench mark, yet red
        flag. Technologically savvy we don't want to boil the ocean so we need
        to have a Come to Jesus meeting with Phil about his attitude, for if
        you're not hurting you're not winning cross sabers t-shaped individual
        we need a recap by eod, cob or whatever comes first. Critical mass.
        Prethink.
      </p>
    </div>
  </section>
</template>

<script>
var $ = require("jquery");
$(document).ready(function () {
  $(".acc__title").click(function (j) {
    var dropDown = $(this).closest(".acc__card").find(".acc__panel");
    $(this).closest(".acc").find(".acc__panel").not(dropDown).slideUp();

    if ($(this).hasClass("active")) {
      $(this).removeClass("active");
    } else {
      $(this).closest(".acc").find(".acc__title.active").removeClass("active");
      $(this).addClass("active");
    }

    dropDown.stop(false, true).slideToggle();
    j.preventDefault();
  });
});
import store from "../store/index.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  data() {
    return {
      isLoading: false,
      fullPage: true,
    };
  },
  components: {
    Loading,
  },
  methods: {
    previousPage: function () {
      store.dispatch("goToPreviousPage");
    },
    nextPage: function () {
      store.dispatch("goToNextPage");
    },
  },
};
</script>

<style>
@import "/assets/styles/style.css";
</style>
