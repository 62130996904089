import { createRouter, createWebHistory } from "vue-router";
//import DocumentDeliveryOptions from '../components/DocumentDeliveryOptions.vue'
import ConfirmAppointment from "../components/ConfirmAppointment.vue";
import DropOffConfirm from "../components/DropOffConfirm.vue";
import ConfirmPickup from "../components/ConfirmPickup.vue";
import DocumentDeliveryComplete from "../components/DocumentDeliveryComplete.vue";
import PrintDocument from "../components/PrintDocument.vue";
import DropOffConfirmNoPrinter from "../components/DropOffConfirmNoPrinter.vue";
import SchedulingOptions from "../components/SchedulingOptions.vue";

const routes = [
  {
    path: "/",
    name: "scheduling-options",
    component: SchedulingOptions,
  },
  {
    path: "/confirmAppointment",
    name: "ConfirmAppointment",
    component: ConfirmAppointment,
  },
  {
    path: "/dropOffConfirm",
    name: "DropOffConfirm",
    component: DropOffConfirm,
  },
  {
    path: "/confirmPickup",
    name: "ConfirmPickup",
    component: ConfirmPickup,
  },
  {
    path: "/documentDeliveryComplete",
    name: "DocumentDeliveryComplete",
    component: DocumentDeliveryComplete,
  },
  {
    path: "/printDocument",
    name: "PrintDocument",
    component: PrintDocument,
  },
  {
    path: "/dropOffConfirmNoPrinter",
    name: "DropOffConfirmNoPrinter",
    component: DropOffConfirmNoPrinter,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    next({ name: to.name, query: from.query });
  } else {
    next();
  }
});

export default router;

function hasQueryParams(route) {
  return !!Object.keys(route.query).length;
}
