<template>
  <section>
    <div class="option_header">
      <h2>Review available drop off locations</h2>
      <p class="option_sub_title">
        We will send status updates to
        <span>{{ cellPhoneNumber }}</span>
      </p>
    </div>
    <div class="option_section">
      <form action="#">
        <div class="option_selected_data" style="display: block">
          <Maps
            v-bind:centralCoordinates="centralCoordinatesDetails"
            v-bind:dropOffLocations="dropOffLocations"
            v-if="centralCoordinatesDetails.latlang"
          />
          <div class="address_btn">
            <ul class="p_l_20">
              <li>
                <span>Your Address</span>
                <strong
                  >{{ customerAddress?.StreetLines[0] }}
                  {{ customerAddress?.StreetLines[1] }}
                  {{ customerAddress?.City }}
                  {{ customerAddress?.StateOrProvinceCode }}
                  {{ customerAddress?.PostalCode }}</strong
                >
              </li>
              <li class="tbl_headaing">Available drop off locations</li>
              <li class="data_table">
                <table>
                  <tr>
                    <th style="width: 40%">Address</th>
                    <th style="width: 20%">Distance</th>
                    <th style="width: 40%">Available Services</th>
                  </tr>
                  <tr v-for="(location, index) in locations" :key="index">
                    <td>
                      {{
                        location.LocationDetail.LocationContactAndAddress
                          .Address.StreetLines[0]
                      }}
                    </td>
                    <td>{{ location.Distance.Value }}</td>
                    <td>{{ getServiceCapabilities(location) }}</td>
                  </tr>
                </table>
              </li>
            </ul>
            <input @click="getShippingLabel" type="button" value="Confirm" />
            <a @click="previousPage" class="back_link" href="#!">Back</a>
          </div>
        </div>
      </form>
    </div>
    <div class="footer_text">
      <p>
        {{disclaimerText}}
      </p>
    </div>
  </section>
</template>

<script>
import store from "../store/index.js";
import Maps from "./Maps.vue";
import router from '../router/index.js'
import { AsYouType } from 'libphonenumber-js'

export default {
  components: {
    Maps,
  },
  data() {
    let latlang;
    if (
      store.state.locations &&
      store.state.locations.AddressToLocationRelationships
    ) {
      let matchedAddressGeographicCoordinates =
        store.state.locations.AddressToLocationRelationships[0]
          .MatchedAddressGeographicCoordinates;
      latlang = matchedAddressGeographicCoordinates;

      this.dropOffLocations =
        store.state.locations.AddressToLocationRelationships[0].DistanceAndLocationDetails;
      let primaryAddress = store.state.applicationDetails.Addresses.find(
        (address) => address.AddressType === store.state.selectedAddressType
      );

      this.customerAddress = {
        StreetLines: [primaryAddress.AddressLine1, primaryAddress.AddressLine2],
        City: primaryAddress.AddressCity,
        StateOrProvinceCode: primaryAddress.AddressState,
        PostalCode: primaryAddress.AddressZipCode,
        CountryCode: "US",
      };
    }
    return {
	centralCoordinatesDetails : {					
		address : this.customerAddress,
		latlang : latlang,
	},
        dropOffLocations: this.dropOffLocations,
    cellPhoneNumber : new AsYouType('US').input(store.state.applicationDetails.CellPhoneNumber),

    };
  },
  methods: {
    previousPage: function () {
      store.dispatch("goToPreviousPage");
    },
    nextPage: function () {
      store.dispatch("goToNextPage");
    },
    getServiceCapabilities(location) {
      let locationCapabilities = location.LocationDetail.LocationCapabilities;
      let serviceList = [];
      let nameList = [];
      locationCapabilities.forEach((capability) => {
        let added = false;
        serviceList.forEach((name) => {
          if (name == capability.TransferOfPossessionType) {
            added = true;
          }
        });
        if (!added) {
          if (capability.TransferOfPossessionType == "DROPOFF") {
            nameList.push("Drop Off");
          } else if (
            capability.TransferOfPossessionType == "HOLD_AT_LOCATION"
          ) {
            nameList.push("Hold at Location");
          } else {
            nameList.push(capability.TransferOfPossessionType);
          }
          serviceList.push(capability.TransferOfPossessionType);
        }
      });
      return nameList.join(", ");
    },
    getShippingLabel() {
      store.dispatch("fetchShippingLabel");
      router.push("/printDocument");
    },
  },
  computed: {
    locations() {
      return store.state.locations.AddressToLocationRelationships[0]
        .DistanceAndLocationDetails;
    },
    disclaimerText() {
      return store.state.disclaimerResponse.find(element => element.ScreenId == 3).DisclaimerText;
    }
  },
};
</script>

<style>
@import "/assets/styles/style.css";
</style>
