<template>
  <section>
    <div class="option_header">
      <h2>Review available drop off locations</h2>
      <p class="error-title">
        If you are unable to print your documents, select a dropoff location
        which offers print services
      </p>
      <p class="option_sub_title">
        We will send status updates to <span>(555) 555-5555</span>
      </p>
    </div>
    <div class="option_section">
      <form action="#">
        <div class="option_selected_data" style="display: block">
          <iframe
            src=""
            width="100%"
            height="172"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
          <div class="address_btn">
            <ul class="p_l_20">
              <li>
                <span>Your Address</span
                ><strong>123 Memory Lane, Dallas, TX 75252</strong>
              </li>
              <li class="tbl_headaing">Available drop off locations</li>
              <li class="data_table">
                <table>
                  <tr>
                    <th style="width: 40%">Address</th>
                    <th style="width: 20%">Distance</th>
                    <th style="width: 40%">Available Services</th>
                  </tr>
                  <tr>
                    <td>Available drop off locations</td>
                    <td>0.2</td>
                    <td>Available drop off locations</td>
                  </tr>
                  <tr>
                    <td>Available drop off locations</td>
                    <td>0.2</td>
                    <td>Available drop off locations</td>
                  </tr>
                  <tr>
                    <td>Available drop off locations</td>
                    <td>0.2</td>
                    <td>Available drop off locations</td>
                  </tr>
                </table>
              </li>
            </ul>
            <input type="button" value="Confirm" />
            <a class="back_link" href="#!">Back</a>
          </div>
        </div>
      </form>
    </div>
    <div class="footer_text">
      <p>
        {{disclaimerText}}
      </p>
    </div>
  </section>
</template>

<script>
var $ = require("jquery");
$(document).ready(function () {
  $(".acc__title").click(function (j) {
    var dropDown = $(this).closest(".acc__card").find(".acc__panel");
    $(this).closest(".acc").find(".acc__panel").not(dropDown).slideUp();

    if ($(this).hasClass("active")) {
      $(this).removeClass("active");
    } else {
      $(this).closest(".acc").find(".acc__title.active").removeClass("active");
      $(this).addClass("active");
    }

    dropDown.stop(false, true).slideToggle();
    j.preventDefault();
  });
});

import store from "../store/index.js";

export default {
  computed: {
    disclaimerText() {
      return store.state.disclaimerResponse.find(element => element.ScreenId == 3).DisclaimerText;
    }
  }
};
</script>

<style>
@import "/assets/styles/style.css";
</style>
