<template>
  <section>
    <div class="option_header">
      <h2>Confirm your pickup details below</h2>
      <p class="option_sub_title">
        We will send status updates to
        <span>(555) 555-5555</span>
      </p>
    </div>
    <div class="option_section">
      <form action="#">
        <div class="option_selected_data" style="display: block">
          <iframe
            src=""
            width="100%"
            height="172"
            style="border: 0"
            allowfullscreen
            loading="lazy"
          ></iframe>
          <div class="address_btn">
            <ul class="p_l_20">
              <li>
                <span>Pickup Address</span>
                <strong>123 Memory Lane, Dallas, TX 75252</strong>
              </li>
              <li>
                <span>Pickup Time</span>
                <strong>12:55PM on May 7, 2021</strong>
              </li>
              <li>
                <div class="acc">
                  <div class="acc__card">
                    <div class="acc__title">Choose different pickup time</div>
                    <div class="acc__panel">
                      <ul>
                        <li>
                          <input type="radio" id="test1" name="radio-group" />
                          <label for="test1">
                            <span>1:23PM on May 7, 2021</span>
                            <i>Funds Today</i>
                          </label>
                        </li>
                        <li>
                          <input type="radio" id="test2" name="radio-group" />
                          <label for="test2">
                            <span>1:23PM on May 7, 2021</span>
                            <i>Funds Tomorrow</i>
                          </label>
                        </li>
                        <li>
                          <input type="radio" id="test3" name="radio-group" />
                          <label for="test3">
                            <span>1:23PM on May 7, 2021</span>
                            <i>Funds by May 10, 2021</i>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <input
              @click="nextPage"
              type="button"
              value="Confirm Appointment"
            />
            <a @click="previousPage" class="back_link" href="#!">Back</a>
          </div>
        </div>
      </form>
    </div>
    <div class="footer_text">
      <p>
        Old boys club thinking outside the box. Pass the mayo, appeal to the
        client, sue the vice president teams were able to drive adoption and
        awareness everyone thinks the soup tastes better after they’ve pissed in
        it punter quarterly sales are at an all-time low yet back-end of third
        quarter. Pipeline build on a culture of contribution and inclusion. All
        hands on deck how much bandwidth do you have quick-win nor this is
        meaningless loop back. Please advise soonest circle back and post launch
        highlights yet low-hanging fruit so can we align on lunch orders, and
        synergestic actionables. How much bandwidth do you have performance
        review for a tentative event rundown is attached for your reference,
        including other happenings on the day you are most welcome to join us
        beforehand for a light lunch we would also like to invite you to other
        activities on the day, including the interim and closing panel
        discussions on the intersection of businesses and social innovation, and
        on building a stronger social innovation eco-system respectively yet
        driving the initiative forward bake it in. Regroup bench mark, yet red
        flag. Technologically savvy we don't want to boil the ocean so we need
        to have a Come to Jesus meeting with Phil about his attitude, for if
        you're not hurting you're not winning cross sabers t-shaped individual
        we need a recap by eod, cob or whatever comes first. Critical mass.
        Prethink.
      </p>
    </div>
  </section>
</template>

<script>
var $ = require("jquery");
$(document).ready(function () {
  $(".acc__title").click(function (j) {
    var dropDown = $(this).closest(".acc__card").find(".acc__panel");
    $(this).closest(".acc").find(".acc__panel").not(dropDown).slideUp();

    if ($(this).hasClass("active")) {
      $(this).removeClass("active");
    } else {
      $(this).closest(".acc").find(".acc__title.active").removeClass("active");
      $(this).addClass("active");
    }

    dropDown.stop(false, true).slideToggle();
    j.preventDefault();
  });
});

import store from "../store/index.js";
export default {
  data() {
    return {};
  },
  components: {},
  methods: {
    previousPage: function () {
      store.dispatch("goToPreviousPage");
    },
    nextPage: function () {
      store.dispatch("goToNextPage");
    },
  },
};
</script>

<style>
@import "/assets/styles/style.css";
</style>
